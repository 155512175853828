import './App.css';
// eslint-disable-next-line no-unused-vars
import { useLocation } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import { NavigationBar } from './Components/Comp/NavigationBar';
import { HomePage } from './Components/Pages/HomePage';
import { DashBoard } from './Components/Pages/DashBoard'
import { Profiles } from './Components/Pages/Profiles';
import { LoginPage } from './Components/Pages/LoginPage';
import { RegistrationPage } from './Components/Pages/RegistrationPage';

function App() {

  const { pathname } = useLocation();
  const allowedPaths = ['/loginpage', '/registration',];
  const isLoginPage = allowedPaths.includes(pathname);

  // const isLoginPage = pathname === ['/loginpage', '/registration'];

  return (
    <div className="App">
      {!isLoginPage && <NavigationBar />}
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/dashboard' element={<DashBoard />} />
        <Route path='/profiles' element={<Profiles />} />
        <Route path='/loginpage' element={<LoginPage />} />
        <Route path='/registration' element={<RegistrationPage />} />
      </Routes>
    </div>
  );
}

export default App;
