import React from 'react'

import '../../StyleSheet/HomePage.css'

// Images Imports
import img1 from '../../Assets/01img.jpg'
import img2 from '../../Assets/02img.jpg'
import img3 from '../../Assets/03img.jpg'
import joinUs from '../../Assets/joinUs.gif'
import aboutUs from '../../Assets/aboutUs.gif'
import aboutUsBWP from '../../Assets/AboutUsBGW.gif'

export const HomePage = () => {

    const carouselStyle = {
        height: "100vh"
    }

    return (
        <>
            <div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img style={carouselStyle} src={img1} className="d-block w-100" alt="..." />
                    </div>
                    <div className="carousel-item">
                        <img style={carouselStyle} src={img2} className="d-block w-100" alt="..." />
                    </div>
                    <div className="carousel-item">
                        <img style={carouselStyle} src={img3} className="d-block w-100" alt="..." />
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>

            <div className='container Height100DVH DF_AIC_JCC'>
                <div className='row g-3'>
                    <div className='col-sm-12 col-md-6 col-lg6 DF_AIC_JCC'>
                        <div>
                            <img src={aboutUs} alt='aboutUs' />
                            <h1 className='StandardXXlargeFontSize FontStyleLobster'>
                                <span className=''>Welcome to Our <br /> Open Source Community</span>
                            </h1>

                            <span className='fs-4 fw-200 FontStyleOpenSans'>
                                <ul>
                                    <li className='listItem'>At <span className='text-primary fw-bold'>E-Profile,</span> we are a passionate group of developers and problem solvers who love the world of coding.</li>
                                    <li className='listItem'>We believe in the power of open source and its ability to drive innovation, collaboration, and real-time problem-solving.</li>
                                </ul>
                            </span>
                        </div>
                    </div>

                    <div className='col-sm-12 col-md-6 col-lg6'>
                        <img className='card-img rounded-3' src={aboutUsBWP} alt='..' />
                    </div>
                </div>
            </div>

            <div className='container Height100DVH DF_AIC_JCC'>
                <div className='row g-3'>
                    <div className='col-sm-12 col-md-6 col-lg6'>
                        <img className='card-img rounded-3' src={aboutUsBWP} alt='aboutUsBWP' />
                    </div>

                    <div className='col-sm-12 col-md-6 col-lg6 DF_AIC_JCC'>
                        <div>
                            <img src={joinUs} alt='joinUs' />
                            <h1 className='StandardXXlargeFontSize FontStyleLobster'>
                                <span className=''>Join Our Community</span>
                            </h1>
                            <span className='fs-4 fw-200 FontStyleOpenSans'>
                                <ul>
                                    <li className='listItem'>Whether you are an experienced developer or just starting your coding journey, we invite you to join our open source community.</li>
                                    <li className='listItem'>Together, let's push the boundaries of what's possible, collaborate on exciting projects, and make a difference through code.</li>
                                    <li className='listItem'>Get involved today and become a part of our vibrant community of passionate developers!</li>
                                </ul>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}