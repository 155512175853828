import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
    name: "userDetails",
    initialState: [],
    reducers: {
        addUser(state, acton) { },
        removeUser(state, action) { },
    },
});

export default userSlice.reducer;
export const { addUser } = userSlice.actions;