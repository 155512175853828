import React, { useEffect, useState } from 'react'
import { CardComponent } from '../Comp/CardComponent'

export const Profiles = () => {

    const [listOfUsers, setListOfUsers] = useState([])

    useEffect(() => {

        const fetchUserDetails = async () => {
            const req = localStorage.getItem('UserLoginDetails');
            const res  = JSON.parse(req);
            if (res) {
                if (res.code === "200") {
                    const requestOptions = {
                        method: 'GET',
                        headers: { "Authorization": res.jwt_tkn },
                        redirect: 'follow'
                    };
                    try {
                        const response = await fetch(process.env.REACT_APP_USERS_API, requestOptions);
                        const data = await response.json();
                        if (data.code === "200") {
                            const users = data.message;
                            setListOfUsers(users);
                        } else {
                            setListOfUsers([]);
                        }
                    } catch (error) {
                        console.log('error', error);
                    }
                }
            } else {
                console.log("error");
            }
        };
        fetchUserDetails();

    }, [])

    return (
        <>
            <div className='container-xl mt-4'>
                <div className='row g-3'>
                    {listOfUsers?.map((item, i) => (<CardComponent props={item} key={item._id} />))}
                </div>
            </div>
        </>
    )
}
