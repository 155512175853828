import React from 'react'
import { Link } from 'react-router-dom'

export const NavigationBar = () => {

    const navbarULstyle = {
        bsScrollHeight: "100px",
    }

    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                <div className="container-xl">
                    <h6 className="navbar-brand m-0" href={'.'}>E-Profile</h6>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarScroll">
                        <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll" style={navbarULstyle}>
                            <li className="nav-item">
                                <Link className="nav-link active" aria-current="page" to={'/'}>Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to={'/profiles'}>Profile's</Link>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href={'.'} role="button" data-bs-toggle="dropdown" aria-expanded="false">Link</a>
                                <ul className="dropdown-menu">
                                    <li><Link className="dropdown-item" style={{ color : "black", }} to={'/dashboard'}>Dashboard</Link></li>
                                    <li><a className="dropdown-item" href={'.'}>Another action</a></li>
                                    <li> <hr className="dropdown-divider"/> </li>
                                    <li><a className="dropdown-item" href={'.'}>Something else here</a></li>
                                </ul>
                            </li>
                        </ul>

                        {/* <form className="d-flex" role="search">
                            <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                            <button className="btn btn-outline-success" type="submit">Search</button>
                        </form> */}
                        <button className='btn btn-primary btn-sm'><Link className="nav-link" style={{ color : "white", }} to={'/loginpage'}>Login</Link></button>
                    </div>
                </div>
            </nav>
        </>
    )
}