import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

// imports reducers.
import { useDispatch, useSelector } from 'react-redux';
import { userAuthValidation } from '../../store/slices/AuthSlice/UserAuthSlice';

// External Component Imports
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Assets Imports
import loginImg from '../../Assets/AR7.jpeg';
import leftSvg from '../../Assets/leftSvg.svg';

export const LoginModule = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { loading, error } = useSelector(state => state.userAuth);

    const notify = (info) => {
        if (info.status === "success") {
            toast(info.message)
        } else if (info.status === "emptyField") {
            toast.warning(info.message)
        } else {
            toast.error(info.message)
        }
    };

    const [formData, setFormData] = useState({
        email: '',
        password: '',
    })

    const handleInputChange = (e) => {
        const { name, value } = e.target
        setFormData(prev => ({
            ...prev,
            [name]: value,
        }));
    }

    const handelSubmitLogin = (e) => {
        e.preventDefault()

        var info = { status: "emptyField", "message": "Both fields are mandatory..!" }
        // Null Check for email and password.
        if (formData) {
            if (formData.email && formData.password) {
                dispatch(userAuthValidation(formData)).then((res) => {
                    // console.log("res : ", res.payload);
                    if (res.payload.code === "200") {
                        // console.log("Data : ", res.payload.message)
                        setFormData({ email: '', password: '' });
                        notify(res.payload)
                        setTimeout(() => {
                            navigate('/dashboard', { replace: true });
                        }, 1500);
                    } else if (res.payload.code === "401") {
                        notify(res.payload);
                    } else {
                        info = { status: "failed", "message": "Issue with login, Please try again some time..!" }
                        notify(info);
                    }
                }).catch((error) => {
                    info = { status: "failed", "message": "Something went wrong, Please try again some time..!" }
                    notify(info)
                    // console.log('error : ', error)
                });
            } else {
                notify(info)
            }
        } else {
            notify(info)
        }
    }

    if (error) {
        notify(error)
    }

    return (
        <div id="print-section" className='container-fluid p-0 d-flex align-items-center justify-content-center' style={{ height: "100vh", backgroundImage: `URL(${loginImg})` }}>
            <div className='row d-flex align-items-center justify-content-center'>
                <div className='col-sm-12 col-md-6 col-lg-4'>
                    <div className="card shadow w-100">
                        <div className="mt-1">
                            <Link to={'/'}>
                                <button className='btn btn-live' title='Home'><img src={leftSvg} alt='..' /></button>
                            </Link>
                        </div>

                        <div className="card-body d-flex align-items-center">
                            <form className='p-3 rounded w-100'>
                                <div className='text-center mb-3'>
                                    <img width="58" height="58" src="https://img.icons8.com/fluency/48/gender-neutral-user.png" alt="gender-neutral-user" />
                                    <h1>Login</h1>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="exampleInputEmail1" className="form-label">Email</label>
                                    <input value={formData.email} onChange={(e) => handleInputChange(e)} name='email' type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" required />
                                    {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
                                    <input value={formData.password} onChange={(e) => handleInputChange(e)} name="password" type="password" className="form-control" id="exampleInputPassword1" required />
                                </div>
                                <button type="submit" className="btn btn-primary w-100 mb-3" onClick={handelSubmitLogin}>{ loading ? "Loading.." : "Submit"}</button>
                                <div className='d-flex align-items-center justify-content-between'>
                                    <p><Link style={{ textDecoration: "none", fontWeight: "300" }} to={"/registration"}>New Here.?</Link></p>
                                    <p><Link style={{ textDecoration: "none", fontWeight: "300" }} to={"/"}>Forget Password.?</Link></p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
}