import React, { useEffect, useState } from 'react'

export const DashBoard = () => {

  const [userDetails, setUserDetails] = useState()

  useEffect(() => {
    const req = localStorage.getItem('UserLoginDetails');
    const res = JSON.parse(req);
    if (res) {
      if (res.code !== "200") {
        setUserDetails(null)
      } 
      setUserDetails(res);
    } else {
      setUserDetails(null)
    }
  }, [])

  return (
    <div className="container">
      <div className="alert alert-primary d-flex align-items-center m-2" role="alert">
        Welcome Sir/Ma'am, You {userDetails ? userDetails.message : 'No User'}
      </div>
    </div>
  )
}
