import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const userAuthValidation = createAsyncThunk(process.env.REACT_APP_LOGIN_API, async (userCredentials) => {
    // console.log("userCredentials : ", userCredentials);
    const req = axios.post(process.env.REACT_APP_LOGIN_API, userCredentials);
    // console.log("res : ", (await req).data);
    const res = (await req).data;
    if (res.code === "200") {
        localStorage.setItem("UserLoginDetails", JSON.stringify(res));
    } else {
        localStorage.removeItem("UserLoginDetails");
    }
    return res;
})

const UserAuthSlice = createSlice({
    name: "userAuth",
    initialState: {
        data: [],
        loading: false,
        error: null,
    },
    extraReducers: (builder) => {
        builder
            .addCase(userAuthValidation.pending, (state) => {
                // console.log("pending");
                state.loading = true;
                state.data = [];
                state.error = null;
            })
            .addCase(userAuthValidation.fulfilled, (state, action) => {
                // console.log("action : ", action.payload);
                state.loading = false;
                state.data.push(action.payload);
                state.error = null;
            })
            .addCase(userAuthValidation.rejected, (state, action) => {
                // console.log("rejected");
                state.loading = false;
                state.data = [];
                state.error.push(action.payload);
            })
    }
});

export const { userLoginFunction } = UserAuthSlice.actions;
export default UserAuthSlice.reducer;