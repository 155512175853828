import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./slices/UserSlice/UserSlice";
import UserAuthSlice from "./slices/AuthSlice/UserAuthSlice";

const store = configureStore({
    reducer: {
        userDetails: userSlice,
        userAuth: UserAuthSlice,
    },
});

export default store;