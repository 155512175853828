import React from 'react'
import "../../StyleSheet/CardStyle.css";
import defaultImg from "../../Assets/account-96.png"

export const CardComponent = (props) => {

    const { email, firstName, lastName, profileImg, experience } = props.props

    return (
        <>
            <div className='col-sm-12 col-md-3 col-lg-3'>
                <div className="card cardMain border-0 text-bg-light">
                    <div className="row g-0">
                        <div className="col-4">
                            <img className="img-fluid rounded-start card-img" src={profileImg ?? defaultImg} alt="..." style={{ width: "100vw" }} />
                        </div>
                        <div className="col-8">
                            <div className="card-body">
                                <h5 className="card-title text-capitalize">{firstName ?? "First Name"} {lastName ?? "Last Name"}</h5>
                                <p className="card-text opacity-50">{experience ?? "X Years of Experience"}</p>
                                <a href={email} className="card-link"><p className="card-text"><small className="text-muted">{email ?? "Card Links"}</small></p></a>
                            </div>
                        </div>
                        <div className='card-footer d-flex align-items-center justify-content-between'>
                            <button className='btn'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bookmark" viewBox="0 0 16 16">
                                    <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5V2zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1H4z" />
                                </svg>
                            </button>

                            <button className='btn'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="gray" className="bi bi-share-fill" viewBox="0 0 16 16">
                                    <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}