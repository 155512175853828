import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

// External Component Imports
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Assets Imports
import loginImg from '../../Assets/AR7.jpeg';
import leftSvg from '../../Assets/leftSvg.svg';

export const RegistrationModule = () => {

    const navigate = useNavigate();

    const notify = (info) => {
        if (info.status === "success") {
            toast(info.message)
        } else if (info.status === "emptyField") {
            toast.warning(info.message)
        } else {
            toast.error(info.message)
        }
    };
    const [data, setData] = useState()

    const handleInputChange = (e) => {
        const { name, value } = e.target
        setData(prev => ({
            ...prev,
            [name]: value,
        }));
    }

    const handelSubmitLogin = (e) => {
        e.preventDefault()

        var info = { status: "emptyField", "message": "All fields are mandatory..!" }
        // Null Check for email and password.
        if (data) {
            if (data.email && data.password && data.firstname && data.lastname) {

                var myHeaders = new Headers();
                myHeaders.append("Accept", "application/json");
                myHeaders.append("Content-Type", "application/json");
              
                var raw = JSON.stringify({
                    "firstName": data.firstname,
                    "lastName": data.lastname,
                    "email": data.email,
                    "password": data.password,
                });

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };

                fetch(process.env.REACT_APP_REGISTRATION_API, requestOptions).then(async (res) => {
                    let info = await res.json()
                    // console.log("res", info)
                    notify(info)
                    setTimeout(() => {
                        navigate('/loginpage',{ replace: true });
                    }, 1000);
                }).catch((error) => {
                    info = { status: "failed", "message": "Something went wrong, Please try again some time..!" }
                    notify(info)  
                    console.log('error : ', error)
                });

            } else {
                notify(info)
            }
        } else {
            notify(info)
        }
    }

    return (
        <div className='container-fluid p-0 d-flex align-items-center justify-content-center' style={{ height: "100vh", backgroundImage: `URL(${loginImg})` }}>
            <div className='row d-flex align-items-center justify-content-center'>
                <div className='col-sm-12 col-md-6 col-lg-4'>
                    <div className="card border-0 w-100">
                        <div className="mt-1">
                            <Link to={'/'}>
                                <button className='btn btn-live' title='Home'><img src={leftSvg} alt='..' /></button>
                            </Link>
                        </div>

                        <div className="card-body d-flex align-items-center">
                            <form className='p-3 rounded w-100'>
                                <div className='text-center mb-3'>
                                    <img width="58" height="58" src="https://img.icons8.com/fluency/48/gender-neutral-user.png" alt="gender-neutral-user" />
                                    <h1>Registration</h1>
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="exampleInputFirstname" className="form-label">First Name</label>
                                    <input onChange={(e) => handleInputChange(e)} name='firstname' type="text" className="form-control" placeholder="Sam" aria-label="FirstName" required/>
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="exampleInputLastname" className="form-label">Last Name</label>
                                    <input onChange={(e) => handleInputChange(e)} name='lastname' type="text" className="form-control" placeholder="leonardo" aria-label="LastName" required/>
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="exampleInputEmail1" className="form-label">Email address</label>
                                    <input onChange={(e) => handleInputChange(e)} name='email' type="email" className="form-control" placeholder="sam@gmail.com" id="exampleInputEmail1" aria-describedby="emailHelp" required />
                                    <span id="emailHelp" className="form-text">We'll never share your email with anyone else.</span>
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
                                    <input onChange={(e) => handleInputChange(e)} name="password" type="password" className="form-control" placeholder="*****" id="exampleInputPassword1" required />
                                </div>

                                <div className='text-center'>
                                    <button type="submit" className="btn btn-primary m-1 w-100" onClick={handelSubmitLogin}>Register</button>
                                    <button type="submit" className="btn btn-text-primary m-1 w-100"><Link className='nav-link text-primary' to={'/loginpage'}>Already have Account.?</Link></button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}